.account-pages {
  width: 100%;
  height: 100vh;
  background-color: #f1f1f1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  font-family: poppins;
  &-auth-main {
    width: 100%;
    height: 100%;
  }
  &-container {
    margin: 0px auto;
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    border-radius: 20px;
  }
  &-warpper {
    margin: 0px auto;
    width: 750px;
    height: 500px;
    display: flex;
    background-color: #ffff;
    border-radius: 20px;
    &-first {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #33a6cd;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    &-second {
      width: 50%;
      height: 100%;
      padding: 20px 20px 20px 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      &-image-container {
        width: 50px;
        height: 50px;
      }
    }
  }
  &-brandMark {
    margin: 0px auto;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: #000;
      font-size: 12px;
      font-weight: 650;
    }
  }
}
.authentication-recaptcha {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

body {
  margin: 0;
  font-family: "Arial", sans-serif;
}

.pricing-page {
  text-align: center;
  background-color: #f4f4f4;
}

.navbar-pricing {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

.logo-pricing {
  font-weight: bold;
  font-size: 24px;
}

.nav-links-pricing {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links-pricing li a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

/* Banner */
.banner-pricing {
  background: linear-gradient(90deg, #33a6cd, #47c0e9);
  color: white;
  padding: 50px;
}

.banner-pricing h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.banner-pricing p {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Billing Switch */
.billing-switch-pricing {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  padding: 5px;
  width: 320px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  user-select: none;
}

.billing-option {
  width: 50%;
  text-align: center;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 50px;
  color: #555;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.billing-option.selected {
  background: linear-gradient(90deg, #47c0e9, #33a6cd);
  color: white;
}

.billing-option:not(.selected):hover {
  color: #33a6cd;
}

/* Pricing Cards */
.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 50px 20px;
  flex-wrap: wrap;
}

.card-pricing {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 30px;
  width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  user-select: none;
}

.card-pricing:hover {
  transform: scale(1.05);
  background: linear-gradient(90deg, #47c0e9, #33a6cd);
}

.price {
  font-size: 36px;
  font-weight: bold;
}

.start-btn-pricing {
  background-color: #000;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
}

.start-btn-pricing:hover {
  background-color: #555;
}

/* Responsive Design: Media Queries */

/* Tablet and small desktop screens */
@media (max-width: 1024px) {
  .navbar-pricing {
    flex-direction: column;
    gap: 10px;
  }

  .pricing-cards {
    padding: 20px;
  }
}

/* Mobile screens */
@media (max-width: 768px) {
  .banner-pricing {
    padding: 30px;
  }

  .banner-pricing h1 {
    font-size: 28px;
  }

  .banner-pricing p {
    font-size: 16px;
  }

  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }

  .card-pricing {
    width: 100%;
    max-width: 350px;
    margin-bottom: 20px;
    user-select: none;
  }

  .navbar-pricing {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .nav-links-pricing {
    flex-direction: column;
    gap: 10px;
  }

  .get-started-pricing {
    padding: 10px 15px;
  }

  /* Responsive styles for the billing switch */
  .billing-switch-pricing {
    width: 100%;
    max-width: 320px;
    margin-top: 20px;
    user-select: none;
  }

  .billing-option {
    padding: 10px;
  }
}

/* Custom Footer */
.pricing-footer {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer-link {
  color: #fff;
  text-decoration: none;
  margin-left: 10px;
}

.footer-link:hover {
  text-decoration: underline;
}

.vProfile {
  &-container {
    width: 400px;
    border-radius: 16px;
    background: #fff;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  &-header {
    width: 100%;
    &-picture {
      width: 100%;
      height: 130px;
      position: relative;
      display: flex;
      justify-content: center;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      margin-bottom: 100px;
      background-image: url("../../../images/users/user-bg.png");
      background-size: cover;
      background-position: center;
      &-container {
        width: 160px;
        height: 160px;
        margin-top: 40px;
        display: flex;
        // justify-content: center;
        // align-self: center;
        border-radius: 50%;
        padding: 3px;
        background: #fff;
        img {
          width: 155px;
          height: 155px;
          border-radius: 50%;
        }
        &-icon {
          width: 155px;
          height: 155px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background: #33a6cd;
        }
      }
    }
    &-details {
      width: 100%;
      display: flex;
      justify-content: center;
      &-name {
        font-size: 20px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        color: black;
      }
      &-email {
        font-size: 15px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        color: rgb(150, 150, 150);
      }
    }
  }
  &-separator {
    padding: 10px;
    margin: 5px 10px 5px 10px;
    border-bottom: 2px solid rgb(236, 236, 236);
  }
  &-body {
    padding: 10px;
    &-section-title {
      font-size: 18px;
      font-weight: 700;
      display: flex;
      color: black;
      margin-bottom: 10px;
    }
    &-section-body {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      &-title {
        font-size: 14px;
        font-weight: 600;
        color: black;
      }
      &-text {
        font-size: 14px;
        font-weight: 600;
        color: rgb(126, 126, 126);
        margin-left: 3px;
      }
    }
  }
}

.cProfile {
  &-container {
    width: 100%;
    border-radius: 16px;
    padding: 15px;
    margin-bottom: 20px;
    background: #fff;
  }
}

.uProfile {
  &-container {
    position: relative;
    width: 155px;
    height: 155px;
    border-radius: 50%;
    margin-bottom: 15px;
    img {
      width: 100%;
      height: 100%;
      border: 3px solid #7c7c7c;
      border-radius: 50%;
      object-fit: cover;
    }
    &-icon {
      width: 155px;
      height: 155px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #33a6cd;
      border: 3px solid #7c7c7c;
    }
    &-uploader {
      position: absolute;
      bottom: 0px;
      right: 16px;
      span {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 3px solid #7c7c7c;
      }
    }
  }
}

.message-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  .message-card-content {
    position: relative;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    width: 360px;
    max-width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .message-card-icon {
    position: absolute;
    top: -50px;
    right: 40%;
    // left: 0%;
    width: 90px;
    height: 90px;
    background-color: #0ac050;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    // margin: 0 auto 1rem;

    span {
      font-size: 2rem;
      color: #fff;
    }
  }
  h2 {
    color: #333;
    font-size: 1.2rem;
    margin: 1rem 0 0.5rem;
  }

  p {
    color: #666;
    font-size: 1rem;
    margin: 0 0 1.5rem;
  }
  .message-card-button {
    width: 120px;
    background-color: #0ac050;
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    user-select: none;

    &:hover {
      background-color: #33e678;
    }
  }
}

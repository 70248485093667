$color: white;
$primary: #33a6cd;
$waves: #44bfe8;

.btn-start-review {
  background: $primary;
  cursor: pointer;
  border: 2px solid $primary;
  color: white;
  height: 28px;
  width: 90px;
  text-align: center;
  display: flex;
  //   flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  //   text-transform: uppercase;
  font-family: "Poppins";
  font-size: 11px;
  line-height: 30px;
  font-weight: 600;
  animation: ripple 1.5s linear infinite;
  transition: all 0.7s ease;

  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    outline: none;
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($waves, 0.3), 0 0 0 1px rgba($waves, 0.3),
      0 0 0 3px rgba($waves, 0.3), 0 0 0 5px rgba($waves, 0.3);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($waves, 0.3), 0 0 0 4px rgba($waves, 0.3),
      0 0 0 20px rgba($waves, 0), 0 0 0 30px rgba($waves, 0);
  }
}

// Variables
$primary-color: #33a6cd;
$secondary-color: #f8f7f7;
$blue-color: #3b82f6;
$text-gray: #6b7280;
$border-radius: 4px;

.onb-container {
  background-color: white;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  .onb-content-wrapper {
    display: flex;
    gap: 3rem;
  }

  // Left Column Styles
  .onb-left-col {
    width: 400px;
  }

  .onb-profile-section {
    margin-bottom: 1.5rem;
  }

  .onb-image-wrapper {
    position: relative;
    width: 200px;
    height: 200px;
    margin-bottom: 2rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
      background-color: $secondary-color;
    }

    input[type="file"] {
      display: none;
    }

    .onb-change-photo {
      position: absolute;
      bottom: -1.5rem;
      left: 0;
      color: #33a6cd;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      background-color: transparent;
      border: none;
      margin-top: 1%;
    }
  }

  // Form Field Styles
  .onb-field-group {
    margin-bottom: 1rem;

    label {
      display: block;
      font-size: 0.75rem;
      color: $text-gray;
      margin-bottom: 0.375rem;
    }

    input,
    select {
      width: 100%;
      height: 2.5rem;
      padding: 0 0.75rem;
      background-color: $secondary-color;
      border: none;
      border-radius: $border-radius;
      font-size: 0.875rem;

      &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba($blue-color, 0.2);
      }
    }

    select {
      appearance: none;
      background-image: url("data:image/svg+xml,...");
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      padding-right: 2rem;
    }
  }

  .onb-input-wrapper {
    position: relative;

    span {
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      color: $text-gray;
    }
  }

  // Section Headers
  h3 {
    font-size: 0.75rem;
    font-weight: 600;
    color: $text-gray;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  // Action Buttons
  .onb-actions {
    display: flex;
    gap: 0.75rem;

    button {
      padding: 0.625rem 1.5rem;
      border-radius: $border-radius;
      font-size: 0.875rem;
      cursor: pointer;

      &.onb-save {
        background-color: $primary-color;
        color: white;
        border-color: transparent;
      }

      &.onb-cancel {
        background-color: #3c3c3c;
        color: white;
        border-color: transparent;
      }

      &.onb-delete {
        background-color: #ff0000;
        color: white;
        border-color: transparent;
      }
    }
  }

  // Right Column Styles
  .onb-right-col {
    flex: 1;
  }

  .onb-role-section {
    margin-bottom: 2rem;
  }

  .onb-team-section {
    margin-bottom: 2rem;
  }

  .onb-onboarding-section {
    .onb-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5rem;

      .onb-date {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: $text-gray;
        font-size: 0.875rem;

        svg {
          width: 1rem;
          height: 1rem;
          stroke: currentColor;
          fill: none;
          stroke-width: 2;
        }
      }
    }
  }

  // Progress Bar
  .onb-progress {
    margin-bottom: 1.5rem;

    .onb-progress-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      font-size: 0.75rem;
      color: $text-gray;
    }

    .onb-progress-bar {
      height: 0.25rem;
      background-color: $secondary-color;
      border-radius: 9999px;
      overflow: hidden;

      div {
        height: 100%;
        background-color: $blue-color;
        border-radius: 9999px;
        transition: width 0.3s ease;
      }
    }
  }

  // Tasks List
  .onb-tasks {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .onb-task {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .onb-task-left {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        input[type="checkbox"] {
          width: 1rem;
          height: 1rem;
          border-radius: 0.25rem;
          border: 1px solid #d1d5db;

          &:checked {
            background-color: $blue-color;
            border-color: $blue-color;
          }

          &:disabled {
            opacity: 0.5;
          }
        }

        span {
          font-size: 0.875rem;
          color: $primary-color;

          &.disabled {
            color: $text-gray;
          }
        }
      }

      span {
        font-size: 0.875rem;
        color: $text-gray;
      }
    }
  }
}

// Alert Styles
.onb-alert {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #def7ec;
  color: #03543f;
  padding: 1rem;
  border-radius: $border-radius;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

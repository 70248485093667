//
// Range slider
//

.irs {
    .irs--square {
        cursor: pointer;
    }
}

.irs--round {
    .irs-bar,
    .irs-to,
    .irs-from,
    .irs-single {
        background: $primary !important;
        font-size: 11px;
    }

    .irs-to,
    .irs-from,
    .irs-single {
        top: 5px;
        &:before {
            display: none;
        }
    }

    .irs-line {
        background-color: var(--#{$prefix}tertiary-bg);

        border-color: var(--#{$prefix}border-color);
        cursor: pointer;
    }
    .irs-grid-text {
        font-size: 11px;
        color: var(--#{$prefix}secondary-color);
    }
    .irs-min,
    .irs-max {
        color: var(--#{$prefix}secondary-color);
        background-color: var(--#{$prefix}tertiary-bg);

        font-size: 11px;
    }

    .irs-handle {
        border: 2px solid $primary;
        width: 12px;
        height: 12px;
        top: 31px;
        background-color: $card-bg !important;
        cursor: pointer;
        &:active {
            cursor: pointer;
        }
    }
}

.evaluation-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

.progress-bar-container {
  max-width: 100%;
  margin: 20px auto;
}

.progress-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.progress-step {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1; /* Allow each step to take equal space */
}

.step-circle {
  background-color: #ccc;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.step-label {
  margin-top: 10px;
  font-size: 14px;
  color: #999;
  text-align: center;
}

.step-line {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 2px;
  width: 100%;
  background-color: #ccc;
  z-index: -1;
}

.progress-step:first-child .step-line {
  display: none; /* No line before the first step */
}

.progress-step:last-child .step-line {
  width: 50%; /* Line should be shorter after the last step */
}

.progress-step.active .step-circle {
  background-color: #33a6cd;
}

.progress-step.active .step-label {
  color: #33a6cd;
}

.question {
  margin-bottom: 20px;
}

.question p {
  font-weight: bold;
  margin-bottom: 5px;
}

.choices {
  display: flex;
  gap: 10px;
}

.choice-button {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.choice-button.active {
  background-color: #33a6cd;
  color: white;
  border-color: #33a6cd;
}

.choice-button:hover {
  background-color: #33a6cd;
  color: white;
}

.choice-button:focus {
  outline: none;
}

.form-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.next-button,
.back-button,
.submit-button {
  background-color: #33a6cd;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back-button {
  background-color: #ccc;
}

/* View Evaluation */

.evaluation-view-form-container {
  width: 90%;
  margin: 0 auto;
  /* display: flex; */
  /* justify-content: center; */
  gap: 20px;
  /* border: 3px solid rgb(9, 212, 101); */
}
.evaluation-review-form {
  /* max-width: 800px; */
  /* margin: 0 auto; */
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  font-family: poppins;
}

.evaluation-view-form-left-card-container {
  width: 100px;

  /* border: 3px solid blue; */
}

.evaluation-view-left-card-summary {
  width: 360px;
  padding: 20px;
  background-color: #f8f8f8;

  border-radius: 8px;
}

.scoring-summary-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: 550;
}

.scoring-summary-subTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: 600;
}

// Container for the whole form
.organizer-general-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f1f1f1;

  .multi-step-container {
    display: flex;
    width: 100%;
    max-width: 1000px;
    height: 600px;
    margin: 0 auto;
    background: #f8f9fc;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }

  // Sidebar for the steps
  .multi-step-sidebar {
    width: 25%;
    background-color: #33a6cd;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    //   background-image: url("/path/to/your/image.jpg"); // Replace with your image path
    background-size: cover;
    background-position: center;

    .multi-step-step {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 15px;
      cursor: pointer;

      .multi-step-circle {
        width: 40px;
        height: 40px;
        background-color: #1d3f72;
        color: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 18px;
        transition: background-color 0.3s ease, color 0.3s ease;
      }

      .multi-step-info {
        h4 {
          margin: 0;
          font-size: 14px;
          color: #fefefe;
          text-transform: uppercase;
          transition: color 0.3s ease;
        }

        p {
          margin: 0;
          font-size: 16px;
          color: #fefefe;
          font-weight: bold;
          transition: color 0.3s ease;
        }
      }

      &.multi-step-active {
        .multi-step-circle {
          background-color: #f9c393;
          color: #fff;
        }
        .multi-step-info {
          h4,
          p {
            color: white;
          }
        }
      }

      &:hover {
        .multi-step-circle {
          background-color: #f9c393;
          color: white;
        }
        .multi-step-info {
          h4,
          p {
            color: white;
          }
        }
      }
    }
  }

  // Form container
  .multi-step-form-container {
    width: 75%;
    padding: 40px;
    background-color: white;

    h2 {
      font-size: 28px;
      margin-bottom: 10px;
      color: #1a1a1a;
    }

    p {
      color: #7b8794;
      margin-bottom: 20px;
    }

    // .multi-step-form-group {
    //   margin-bottom: 20px;

    //   label {
    //     display: block;
    //     margin-bottom: 5px;
    //     color: #4a4a4a;
    //     font-weight: bold;
    //   }

    //   input {
    //     width: 100%;
    //     padding: 12px;
    //     border: 1px solid #ddd;
    //     border-radius: 8px;
    //     font-size: 16px;
    //     margin-bottom: 10px;
    //     &:focus {
    //       border-color: #4831d4;
    //       outline: none;
    //       box-shadow: 0 0 5px rgba(72, 49, 212, 0.3);
    //     }
    //   }
    // }

    .multi-step-navigation {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      // margin-top: 30px;

      .multi-step-btn {
        background-color: #1d3f72;
        color: white;
        padding: 12px 24px;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-weight: bold;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #143054;
        }
      }

      .multi-step-prev-btn {
        margin-right: auto;
      }
    }
  }
}

.evaluation-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  font-family: Arial, sans-serif;
}

.section {
  margin-bottom: 20px;
}

.section h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #333;
}

.question {
  margin-bottom: 20px;
}

.question p {
  font-weight: bold;
  margin-bottom: 5px;
}

/* Employee and Manager Choices */
.choices-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.employee-choice,
.manager-choice {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
}

.manager-name {
  font-weight: bold;
  color: #33a6cd;
  margin-left: 10px;
  position: relative;
}
.employee-name {
  font-weight: bold;
  color: #888;
  margin-left: 10px;
  position: relative;
}

/* Vertical line before the names */
.employee-name::before,
.manager-name::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 100%;
}

.employee-name::before {
  background-color: #888;
}

.manager-name::before {
  background-color: #33a6cd;
  height: 100%;
}

/* Choice Buttons */
.choice-button {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.choice-button.active {
  background-color: #33a6cd;
  color: white;
  border-color: #33a6cd;
}

.choice-button.employee-choice-button.active {
  background-color: #888;
  color: white;
  border-color: #888;
}

.choice-button:hover {
  background-color: #33a6cd;
  color: white;
}

.choice-button:focus {
  outline: none;
  border-color: #33a6cd;
}

.employee-choice-button {
  cursor: default;
}

.form-navigation-assignment {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.next-button,
.back-button,
.submit-button {
  background-color: #33a6cd;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button {
  background-color: #ccc;
  margin-right: 10px;
}

.submit-button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

/* signature styling */
.signature-container {
  display: flex;
  text-align: center;
  margin-top: 20px;
}

.sigCanvas {
  border: 2px dashed #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.signature-preview {
  width: 100%;
  max-width: 500px;
  height: 120px;
  margin-top: 10px;
  border-radius: 5px;
  object-fit: contain;
  background-color: #fff;
}
.signature-btn-container {
  margin-left: 7px;
}

.signature-upload-photo {
  margin-top: 10px;
  button {
    border: none;
    background-color: transparent;
    color: #33a6cd;
  }
}

input[type="file"] {
  display: none;
}

/* input[type="file"] {
  margin-top: 20px;
} */

.swiper {
  width: 90%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Override Swiper pagination style */
.swiper-pagination-bullet {
  background: #000;
  opacity: 0.5;
}

.swiper-pagination-bullet-active {
  background: #fff !important;
  opacity: 1;
}

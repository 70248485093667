//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb-item {
    > a {
        color: var(--#{$prefix}emphasis-color);
    }
    + .breadcrumb-item {
        &::before {
            font-family: "Material Design Icons";
        }
    }
}

// RTL

[dir="rtl"] {
    .breadcrumb-item {
        + .breadcrumb-item {
            padding-right: $breadcrumb-item-padding-x;

            &::before {
                font-family: "Material Design Icons";
                padding-left: 0;
                content: "\F0141";
            }
        }
    }
}

.form-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.evaluation-table,
.scoring-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  margin-bottom: 20px;
}

.evaluation-table th,
.evaluation-table td,
.scoring-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.section-title {
  font-weight: bold;
  background-color: #f7f7f7;
}

.total-label {
  font-weight: bold;
  text-align: right;
}

.scoring-system h3 {
  margin-bottom: 10px;
}

.recommendations,
.signature {
  margin-top: 20px;
}

.recommendations textarea,
.signature input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.signature-pad {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.signature-canvas {
  width: 100%;
  height: 150px;
  border: 1px solid #ccc;
}

.signature-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.signature-buttons button {
  padding: 5px 10px;
  cursor: pointer;
}

.signature-preview img,
.uploaded-signature-preview img {
  margin-top: 10px;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.upload-signature input {
  margin-top: 5px;
}
